const { ajaxSetup } = require("jquery");

document.addEventListener("turbolinks:load", () => {
	console.log('hola desde tracking_records.js');

  // const openTrackingModal = (route, routeNew) => {
  //     // console.log(routeNew);
  //     fetch(route+".js")
  //     .then(resp => resp.json())
  //     .then(resp => {
  //       // //console.log(resp);
  //       //   // poner titulo
  //       //   $("#tracking-modal .modal-title").html("Seguimiento corte "+resp.batch.number+" orden " + resp.batch.order);

  //       //   // tabla de eventos
  //       //   var eventos = "";
  //       //   resp.tracking_records.forEach(function(el, ind) {
  //       //     eventos += "<tr><td>"+el.folio+"</td><td>"+el.tracking_type+"</td><td>"+el.area+"</td><td>"+el.area_name+"</td><td>"+el.date+"</td><td>"+el.delivered_pieces+"</td><td>"+el.lost_pieces+"</td><td>"+(el.observations || "")+"</td><td>"+el.created_at+"</td><td>"+(el.user || "")+"</td><td>"+
  //       //       //editar elementos
  //       //       "<i class='fa fa-edit green-text pointer' data-id='"+el.id+"' data-type='edit-tracking-record'></i>"+
  //       //       "</td><td>"+
  //       //       //eliminar elementos
  //       //       '<a data-confirm="Estás seguro?" class="dropdown-item" rel="nofollow" data-remote="true" data-method="delete" href="'+route+'/'+el.id+'.js"><i class="fa fa-trash"></i></a>'+
  //       //       "</td>"+

  //       //       "<td>"+
  //       //       "<!--i class='material-icons "+(el.discount_pieces ? ("blue-text pointer' onclick='abrirDescuento("+el.id+")'") : "'")+" title='"+(el.discount_date ? (el.discount_date + " - " + el.discount_observation) : ("No se ha registrado el descuento"))+"'>"+(el.discount_date ? "attach_money" : "money_off")+"</i-->"+
  //       //       "</td></tr>";
  //       //   });
  //       //   $("#tracking-modal #body-trayecto").html(eventos);

  //       //   const btn = '<a class="btn btn-float btn-small btn-primary pt-2" data-id="" data-type="new-tracking-record" id="boton-agregar-evento">Agregar evento</a>';
  //       //   $("#tracking-modal #btn-new").html(btn);

  //       //   document.querySelector("[data-type='new-tracking-record']").addEventListener("click", (ev) => {
  //       //     ev.preventDefault();
  //       //     crearEvento(resp.batch.id, route, routeNew);
  //       //   });

  //       //   document.querySelectorAll("[data-type='edit-tracking-record']").forEach(function(edit){
  //       //     edit.addEventListener("click", (ev) => {
  //       //       ev.preventDefault();
  //       //       editarEvento(ev.target.dataset.id, route);
  //       //     })
  //       //   });
  //     });
  // }
  // function crearEvento(batch_id, route, routeNew) {
  //   // console.log(batch_id);
  //   // console.log(route);
  //   $.ajax({ url: routeNew, 
  //     dataType: 'JSON', 
  //     data:{batch_id:batch_id}
  //   }).done(function(data) {
  //     // console.log(data);
  //     // llenar select con las etapas que vienen del permiso y las etapas del modelo
  //     var op_select = "<option></option>";
  //     data.areas.map(function(el){return el.value}).forEach(function (el, ind) {
  //       // console.log(el);
  //       // todas las etapas para todos los cortes segun permisos
  //       op_select += ("<option>"+el+"</option>");
  //     });
  //     // llenar el select del modal de nuevo evento
  //     $("#modal-nuevo-evento #input_area").html(op_select);
  //     //quitar para no agregar el nombre que trae en llenar
  //     $("#modal-nuevo-evento #input_area_name").removeData('poner');

  //     $("#form-eventos").attr({ action:route });
  //     $("#form-eventos #patch_form").remove();
  //     //console.log(corte_id);
  //     $("#modal-nuevo-evento #folio_derecha").html("Siguiente folio: "+data.new_folio);
  //     $("#modal-nuevo-evento #input_batch_id").val(batch_id);
  //     $("#input_delivered_pieces").val(data.batch.quantity);
  //     $("#input_lost_pieces").val("0");
  //     $("#modal-nuevo-evento").modal("show");
  //   }).fail(function(err) {
  //     console.log(err);
  //   });
  // }

  // function editarEvento(id ,route) {
  //   // console.log(id);
  //   // console.log(route);
  //   $.ajax({ url: route+"/"+id, dataType: 'JSON'})
  //   .done(function(data) {
  //     // console.log(data);
  //     // llenar select con las etapas que vienen del permiso y las etapas del modelo
  //     var op_select = "<option></option>";
  //     data.areas.map(function(el){return el.value}).forEach(function (el,ind) {
  //       // console.log(el);
  //       // todas las etapas para todos los cortes segun permisos
  //       op_select += ("<option>"+el+"</option>");
  //     })

  //     $("#modal-nuevo-evento #input_batch_id").data({'valor':data.record.batch_id});
  //     // llenar el select del modal de nuevo evento
  //     $("#modal-nuevo-evento #input_area").html(op_select);
  //     $("#modal-nuevo-evento #input_area_name").data("poner",data.record.area_name);
      
  //     $("#form-eventos").attr({action: route+"/"+id});
  //     $("#form-eventos").prepend('<input type="hidden" name="_method" value="patch" id="patch_form">');
  //     // console.log(id);
  //     $("#modal-nuevo-evento #folio_derecha").html("Folio: "+data.record.folio);
  //     $("#modal-nuevo-evento #input_batch_id").val(data.record.batch_id);
  //     $("#modal-nuevo-evento #input_tracking_type").val(data.record.tracking_type).change();
  //     $("#modal-nuevo-evento #input_area").val(data.record.area).change();
  //     $("#modal-nuevo-evento #input_area_name").html("<option>"+data.record.area_name+"</option>");
  //     $("#modal-nuevo-evento #input_area_name").val(data.record.area_name);
  //     $("#modal-nuevo-evento #input_date").val(data.record.date);
  //     $("#modal-nuevo-evento #input_delivered_pieces").val(data.record.delivered_pieces);
  //     $("#modal-nuevo-evento #input_lost_pieces").val(data.record.lost_pieces);
  //     $("#modal-nuevo-evento #input_who_delivers").val(data.record.who_delivers);
  //     $("#modal-nuevo-evento #input_who_receives").val(data.record.who_receives);
  //     $("#modal-nuevo-evento #input_observations").val(data.record.observations);
  //     $("#modal-nuevo-evento").modal("show");
  //   }).fail(function(err) {
  //     console.log(err);
  //   }).always(function() {
  //     $("#fixed-loader").hide();
  //   });
  // }

  // function llenarListaNombres(value, route) {
  //   //console.log(etapa);console.log(maq)
  //   if (value) {
  //     $.ajax({
  //       url: route,
  //       dataType: 'JSON',
  //       data: {area: value},
  //     }).done(function(data) {
  //       // console.log(data);
  //       var opt_nom = "";
  //       data.forEach(function (el,ind) {
  //         opt_nom += "<option>"+el.full_name+"</option>"; 
  //       });
  //       $("#modal-nuevo-evento #input_area_name").html(opt_nom);
  
  //       // si es editar elemento llenar con ele que trae
  //       var poner = $("#modal-nuevo-evento #input_area_name").data("poner");
  //       // console.log(poner);
  //       if (poner) {
  //         // console.log('si poenr');
  //         $("#modal-nuevo-evento #input_area_name").val(poner).change();
  //       }
  //     }).fail(function(err) {
  //       console.log(err);
  //     });
  //   } else{
  //     $("#modal-nuevo-evento #input_area_name").html("");
  //   }
  // }

	// let tracking_table = document.getElementById('tracking-index');
	// // si existe tabla de tracking
	// if (tracking_table) {
  //       document.querySelectorAll("[data-type='open-batch-tracking']").forEach((elem) => {
  //           elem.addEventListener('click', (e)=>{
                
  //           });
  //       });

  //       document.querySelector("#input_area").addEventListener("change", (e) => {
  //         llenarListaNombres(e.target.value, e.target.dataset.route);
  //       });
  //   }
});
