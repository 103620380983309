document.addEventListener("turbolinks:load", () => {
	console.log('hola desde orders.js');

	// para el index
	let btn_open_modal_import = document.getElementById('import-orders-btn');
	if (btn_open_modal_import) {
		// console.log(btn_open_modal_import)
		btn_open_modal_import.addEventListener('click', ev => {
			$('#modal-import-orders').modal('show');
		});

	}

	let order_style_select = document.getElementById('order_style-select');
	if(order_style_select) {
		// quita callbacks por turbolinks
		$(document).off("change","#order_style-select");
		// añade el evento a los campos cuando cambian de valor para recalcular
		$(document).on('change', '#order_style-select', function(event) {
			const models_route = $("#order_style-select").data('route')
			const selected_style = event.target.value
			fetch("/"+models_route+"/by_style.json?style="+selected_style)
				.then(response => response.json())
				.then(response => {
					console.log(response);
					document.getElementById("order_code").value = response.code
					document.getElementById("order_description").value = response.description
					document.getElementById("order_size").value = response.sizes
					document.getElementById("consumption").value = response.fabric_consumption
					$('#order_store').val(response.client).trigger('change');
					let order_cloth_select = $('#order_cloth');
                
                	if (order_cloth_select.find("option[value='" + response.fabric + "']").length) {
                	    order_cloth_select.val(response.fabric).trigger('change');
                	} else {
                	    var newOption = new Option(response.fabric, response.fabric, true, true);
                	    order_cloth_select.append(newOption).trigger('change');
                	}
				})
		});
	}
	
	const amount1 = document.getElementById("amount");
	const consumption1 = document.getElementById('consumption');
	const sale_price = document.getElementById('order_sale_price');
	const total_consumption1 = document.getElementById('total_consumption') 
	const invoice_total = document.getElementById('order_total_invoice') 

	if (consumption1){
		consumption1.addEventListener('change', function() {
			calculateAndUpdate();
		});
	}

	if (sale_price) {
		sale_price.addEventListener('change', function() {
			calculateAndUpdate();
		});
	}

	if (amount1) {
		amount1.addEventListener('change', function() {
			calculateAndUpdate();
		});
	}

	function calculateAndUpdate() {
	  	var get_amount = amount1.value || 0;
	  	var get_consumption = consumption1.value || 0;
	  	var get_sale_price = sale_price.value || 0;
	  	total_consumption1.value = get_amount * get_consumption;
	  	invoice_total.value = get_sale_price * get_amount;
	}

	// initialize existing selects
	const selectElements = $(".select3-batches-oders-fabric")
	if(selectElements.length > 0) {
		const route = selectElements[0].dataset.route
		initializeSelect2Models(".select3-batches-oders-fabric", route)
	}
		
	function initializeSelect2Models(selector, route) {
		$(selector).select2({
			language: {
				"noResults": function(){
				return "Sin resultados";
				}
			},
			ajax: {
				url: (route + ".json"),
				data: function (params) {
				var query = {
					description: params.term,
				}
	
				return query;
				},
				processResults: function (data) {
				// Transforms the top-level key of the response object from 'items' to 'results'
				$.map(data, function (obj) {
					obj.text = obj.description
					obj.id = obj.description
				
					return obj;
				});
	
				return {
					results: data,
				}
				}
			},
			placeholder: 'Selecciona una opción',
		})
	}	
	$('#status-menu li').on('click', function() {
		var dataValue = $(this).data('value');
		var dataId = $(this).data('id');
		if (dataValue == 'delivered'){
			$("#sharedModal .modal-title").html("<h5 class='text-center'>Ingresar fecha de entrega</h5>");
			$("#sharedModal .modal-dialog.modal-xl").removeClass("modal-xl");
			$("#sharedModal .modal-body").html(`
			<div class="card">
			<div class="card-body">
				<div class="form-group row row-sm">
				<label class="col-sm-3 col-form-label">Fecha:</label>
					<div class="col-sm-9">
						<input id="date-delivered" type="date" class="form-control" placeholder="Fecha de entrega" required>
					</div>
				</div>
			</div>
			`);
			if ($("#sharedModal .modal-footer .btn-primary").length === 0) {
			    $("#sharedModal .modal-footer").append("<button type='submit' class='btn btn-primary' id='btnChange'>Guardar</button>");
			}
			$("#sharedModal").modal("show");
			$("#btnChange").on("click", function() {
				var date = $("#date-delivered").val();
				if (date.trim() === "") {
					alert("La fecha es requerida. Por favor, ingrese una fecha.");
				} else {
					var csrfToken = $('meta[name=csrf-token]').attr('content');
					$.ajax({
						url: `/pedidos/${dataId}/change_status?new_status=${dataValue}`,
						method: 'PUT',
						headers: {
							'X-CSRF-Token': csrfToken
						},
						data: { delivery_date: date },
						success: function(response) {
							$("#sharedModal").modal("hide");
						},
						error: function() {
						// Manejo de errores
						}
					});
				}
			});
		}
	});

	$('#switchPacking').change(function() {
		if ($(this).is(':checked')) {
		  	$("#packing_container").removeClass('d-none')
		} else {
			$("#packing_container").addClass('d-none');
			cleanPackingSection();
		}
	});

	function updateQuantityValues() {
	  let quantity = parseFloat($("#amount").val());
	  if ($('#switchPacking').is(':checked')) {
		  let equivalencePacking = parseFloat($("#order_packing_equivalence").val());
		  // Dividir cantidad/cantidad empaque
		  $("#order_packing_quantity").val(quantity / equivalencePacking);
		  let equivalencePackaging = parseFloat($("#order_packaging_equivalence").val());
		  let quantityPackaging = parseFloat($("#order_packing_quantity").val());
		  $("#order_packaging_quantity").val(quantityPackaging / equivalencePackaging);
		}else {
			let equivalencePackaging = parseFloat($("#order_packaging_equivalence").val());
			$("#order_packaging_quantity").val(quantity / equivalencePackaging);
	  	}
	}

	function updateUnitText(elementId, unitTextId) {
	  var selectedText = $(elementId).find("option:selected").text();
	  selectedText += /[aeiou]$/i.test(selectedText) ? "s" : "es";
	  $(unitTextId).text(selectedText);
	}

	$("#amount, #order_packaging_equivalence, #order_packing_equivalence").on('change', function() {
	  updateQuantityValues();
	});

	$("#order_packaging_capacity_id").on('change', function() {
	  updateUnitText(this, "#unit_packaging_capacity");
	});

	$("#order_packing_capacity_id").on('change', function() {
	  updateUnitText(this, "#unit_packing_capacity");
	});

	function cleanPackingSection() {
		let field_ids = ["#order_packing_id", "#order_packing_capacity_id", "#order_packing_unit_id","#order_packing_equivalence", "#order_packing_quantity", "#order_packing_form_id", "#order_packing_specifications"];
		field_ids.forEach(function (field, index) {
			$(field).val(null);
			if (index == 0 || index == 5){
				$(field).trigger("change")
			}
		})
	}
	
});