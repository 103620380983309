document.addEventListener("turbolinks:load", function() {
	const formModels = document.getElementById("models-form")
	if(formModels){
		// initialize existing selects
		const selectElements = $(".select3")
		if(selectElements.length > 0) {
			selectElements.each(function () {
				let route = $(this).data("route");
				if($(this).data('type') == 'nested-product-id') {
					initializeSelect2Models($(this), route, true);
				} else {
					initializeSelect2Models($(this), route, false);
				}
			});
		}
	
		function initializeSelect2Models(selector, route, habilitation) {
			$(selector).select2({
			  language: {
				"noResults": function(){
				  return "Sin resultados";
				}
			  },
			  ajax: {
				url: (route + ".json"),
				data: function (params) {
				  var query = {
					description: params.term,
				  }
		  
				  return query;
				},
				processResults: function (data) {
					console.log(data);
				  // Transforms the top-level key of the response object from 'items' to 'results'
				  var results = $.map(data, function (obj) {
					if (habilitation){
						obj.text = obj.description + '-' + obj.composed_key_code;
						obj.id = obj.id;
						obj.data_unit = obj.output_unit;
						obj.data_product_id = obj.product_id;
					} else {
						obj.text = obj.description;
						obj.id = obj.product_id;
					}
					return obj;
				  });
		  
				  return {
					results: results,
				  }
				}
			  },
			  placeholder: 'Selecciona una opción',
			  templateResult: formatRepo,
			  templateSelection: formatRepoSelection
			})
		  }
		  

		// initialize when inserting new nesteds
		$('#product_models').on('cocoon:after-insert', function(e, insertedItem) {
			var selectInElement = $(insertedItem).find('select:first');
			const selector = "#" + selectInElement.attr('id');

    		initializeSelect2Models(selector, selectInElement.data('route'), true);

			$(selector).on('select2:select', function (e) {
				var selectedElement = e.params.data;
				let parent = $(selector).closest(".nested-fields");
				let unit_nested_field = parent.find("[data-type='unit-nested-field']");
				let data_product_field = parent.find("[data-type='data-product-id']");
				unit_nested_field.val(selectedElement.data_unit);
				data_product_field.val(selectedElement.product_id);
			});
		})
	}

	// Función para formatear los resultados
	function formatRepo(repo) {
		if (repo.loading) {
		  return repo.text;
		}

		var $container = $(
		  "<div class='select2-result-repository clearfix'>" +
			"<div class='select2-result-repository__title'></div>" +
		  "</div>"
		);
	
		$container.find(".select2-result-repository__title").text(repo.text);
		$container.attr('data-unit', repo.data_unit); // Agregamos el atributo data-type
		$container.attr('data_product_id', repo.data_product_id); // Agregamos el atributo data_product_id
	
		return $container;
  	}
  
  	// Función para formatear la selección
  	function formatRepoSelection(repo) {
		return repo.text;
  	}

	function updateThreadValues(id, value) {
		fetch("/search_thread_values?id=" + value + ".json")
		  .then(resp => resp.json())
		  .then(resp => {
			$("#" + id + "_color").val(resp.color);
			$("#" + id + "_gauge").val(resp.gauge);
		  })
		  .catch(err => {
			console.log(err);
		  });
	  }
	  
	  $(document).on('change', '#model_external_thread, #model_internal_thread, #model_overcasting', function (e) {
		let value = e.target.value;
		let id = e.target.id;
		updateThreadValues(id, value);
	  });

	  let lastModelSelect = $('#specifications_models select:last');

	  $('#specifications_models').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
		let type_nested_field = insertedItem[0].querySelector("input[data-type='type_field']");
		type_nested_field.value = 'front';
		
		let previousSelect = $(insertedItem).prev().find('select:first');
		let selectedLetter = previousSelect.val() || (lastModelSelect.length > 0 ? lastModelSelect.val()  : '');

		let nextLetter = selectedLetter ? String.fromCharCode(selectedLetter.charCodeAt(0) + 1) : 'A';

		let newSelect = $(insertedItem).find('select:first');
		newSelect.val(nextLetter);

		const selector = "#" + newSelect.attr('id');
		$(selector).select2();
	  })

	  let lastModelTSelect = $('#specifications_models_t select:last');

	  $('#specifications_models_t').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
		let typeNestedField = insertedItem[0].querySelector("input[data-type='type_field']");
		typeNestedField.value = 'back';
		 
		let previousSelect = $(insertedItem).prev().find('select:first');
		let lastSelect = $('#specifications_models select:last');
		
		let selectedLetter = previousSelect.val() || (lastModelTSelect.length > 0 ? lastModelTSelect.val()  : lastSelect.length > 0 ? lastSelect.val() : '');
	  
		let nextLetter = selectedLetter ? String.fromCharCode(selectedLetter.charCodeAt(0) + 1) : 'A';
	  
		let newSelect = $(insertedItem).find('select:first');
		newSelect.val(nextLetter);
	  
		const selector = "#" + newSelect.attr('id');
		$(selector).select2();
		
	  });
	  
	  $("#btn-select-area").on('click', () => {
		$("#areasModal").modal('show');
	  })
	  $('#specifications_models_t, #specifications_models, #product_models').on('cocoon:after-insert cocoon:after-remove', (e, item) => {
		updateHeightContainer();
	  });

	  function updateHeightContainer() {
		var specifications_height = document.getElementById('specifications').clientHeight;
		var qualifications_height = document.getElementById('qualifications').clientHeight;
		var height = specifications_height + qualifications_height;
		document.getElementById('image-container').style.height = height + 'px';
	  }
});