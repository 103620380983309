document.addEventListener("turbolinks:load", () => {
	// console.log('hola desde print_documents.js');

	let appName = document.querySelector('body').dataset.appName;
	let print_sale_btn = document.getElementById('print-sale-btn');
	if (print_sale_btn) {
		print_sale_btn.addEventListener('click', ev => {
			ev.preventDefault();
			// console.log('imprimir venta');
			let route = print_sale_btn.dataset.modelPath;
			let id = print_sale_btn.dataset.id;
			fetch('/'+route+'/'+id+'.json')
				.then(resp => resp.json())
				.then(resp => {
					// console.log(resp);
					if (resp.document == "NOTA DE VENTA") {
						content = generateNotaDeVenta(resp);
					}else {
						content = generateVenta(resp);
					}
					openPrint(content);
				})
				.catch(error => {
					console.log(error);
				})

		});
	}

	let generateNotaDeVenta = data => {
		//console.log("venta nota de venta");
		let content = "<div style='width=100%'>"+
			'<div style="float:left;width:100%; text-align:center;">'+
				`<h1 style="margin:0">${appName}</h1>`+
	          	'Zacualtipán de Ángeles, Hidalgo, México, CP:43200 <br>'+
	        '</div>'+
	        '<div style="float:left;width:100%;margin-bottom:10px">'+
	          '<strong>'+data.document+ ' NO: </strong><span style="float:right;text-align:right">'+data.number +'</span><br>'+
	          '<strong>Fecha: </strong> <span style="float:right;text-align:right">'+data.inventory_movement.full_date+'</span><br>'+
	          '<strong>Atendido por: </strong> <span style="float:right;text-align:right">'+
	          data.inventory_movement.username+'</span><br>'+
	          '<strong>Registro: </strong> <span style="float:right;text-align:right">'+
	          data.created_at_long+'</span><br>'+
	          data.linked_to +' '+ data.inventory_movement.document+'<br>'+
	          '<strong>Cliente: </strong> <span style="float:right;text-align:right">('+
	          data.inventory_movement.meta.key_code + ') ' + data.name+ '</span><br>'+
			  (data.seller ? '<strong>Vendedor: </strong> <span style="float:right;text-align:right">(' + (data.seller.key_code || '') + ') ' + (data.seller.name || '') + '</span><br>' : ' ') +
	          '<strong>Observaciones: </strong>'+
	          data.inventory_movement.observations+
	        '</div>'+

			'<table style="text-align:center;width:100%;">'+
			'<thead>'+
			  '<tr>'+
				  '<th>Cantidad</th>'+
				  '<th colspan="2">Descripción</th>'+
			  '</tr><tr>'+
			  	  '<th></th>'+
				  '<th style="text-align:right;padding-right:5px">'+(data.inventory_movement.inventory_movement_type == "output" ? 'P' : 'C')+'/Unitario</th>'+
				  '<th style="text-align:right;padding-right:5px">Importe</th>'+
			  '</tr>'+
			'</thead>'+
			'<tbody>';
			data.inventory_movement.product_movements.forEach(function(a){
			    content += '<tr>'+
			      '<td>'+a.quantity+'</td>'+
			      '<td colspan="2">'+a.product_description+'</td>'+
			    '</tr><tr>'+
			      '<td></td>'+
			      '<td style="text-align:right;padding-right:5px">$'+a.unit_cost+'</td>'+
			      '<td style="text-align:right;padding-right:5px;">$'+a.subtotal+'</td>'+
			    '</tr>';
			});
			content += '<tr>'+
				'<td colspan="2" style="text-align:right;padding-right:5px;font-weight:bold;">SUBTOTAL:</td>'+
				'<td style="text-align:right;padding-right:5px;">$'+data.subtotal+'</td>'+
			'</tr>'+
			'<tr><td colpan="3" style="border:0;text-align:center;color:white;">-</td></tr>'+
			'<tr>'+
				'<td colspan="2" style="text-align:right;padding-right:5px;font-weight:bold;border:0">DESC.:</td>'+
				'<td style="text-align:right;padding-right:5px;border:0">- '+data.discount+'</td>'+
			'</tr>'+
			'<tr>'+
				'<td colspan="2" style="text-align:right;padding-right:5px;font-weight:bold;border:0">IVA:</td>'+
				'<td style="text-align:right;padding-right:5px;border:0">+ '+data.iva+'</td>'+
			'</tr>'+
			'<tr>'+
			    '<td colspan="2" style="text-align:right;padding-right:5px;border:0;"><strong>TOTAL:</strong></td>'+
			    '<td style="text-align:right;padding-right:5px;border:0;"><strong>$'+data.total+'</strong></td>'+
			 '</tr>';
			 data.payments_accounts.forEach(function (fp) {
				content += '<tr>'+
				    '<td colspan="2" style="text-align:right;padding-right:5px;border:0;"><strong>'+fp.concept_description+':</strong></td>'+
				    '<td style="text-align:right;padding-right:5px;border:0;"><strong>$'+fp.amount+'</strong></td>'+
				 '</tr>';
			 });
			content += '<tr>'+
			    '<td colspan="2" style="text-align:right;padding-right:5px;border:0;"><strong>TOTAL PAGADO:</strong></td>'+
			    '<td style="text-align:right;padding-right:5px;border:0;"><strong>$'+data.total_paid+'</strong></td>'+
			 '</tr>'+
			'<tr>'+
			    '<td colspan="2" style="text-align:right;padding-right:5px;border:0;"><strong>CRÉDITO:</strong></td>'+
			    '<td style="text-align:right;padding-right:5px;border:0;"><strong>$'+data.on_credit+'</strong></td>'+
			 '</tr>'+
			'</tbody>'+
			'</table>'+
			'<div style="float:left;width:100%;margin-top:10px;text-align:center">'+
			  	numeroALetras(data.total)+
			'</div>'+
		"<div>";
		return content;
	}

	let generateVenta = data => {
		//console.log("venta no nota de venta");
		var content = "<div style='width=100%'>"+
			'<div style="float:left;width:100%; text-align:center">'+
				`<h1 style="margin:0 0 10px 0">${appName}</h1>`+
			'</div>'+
	        '<div style="float:left;width:60%;">'+
	          '<strong class="titulo-formato">Domicilio:</strong><br>'+
	          'Zacualtipán de Ángeles, Hidalgo, México, CP:43200 <br>'+
	          '<strong>Lugar de expedición:</strong><br>'+
	          'Zacualtipán de Ángeles, Hidalgo, México, CP:43200'+
	        '</div>'+
	        '<div style="float:left;width:40%">'+
	          '<strong>'+data.document+ ' NO: </strong>'+data.number +'<br>'+
	          '<strong>Fecha: </strong>'+data.inventory_movement.full_date+'  <br>'+
	          data.linked_to +' '+ data.inventory_movement.document+'<br>'+
	        '</div>'+
	        '<div style="float:left;width:100%;margin-top:10px">'+
	        	'<strong>Cliente: </strong>('+
	        	data.inventory_movement.meta.key_code + ') ' + data.name+
	        	'<br>'+ data.address +'  <strong>RFC: </strong>'+ data.inventory_movement.meta.rfc + ' ' +
	        	' <strong>Teléfono:  </strong>' + data.inventory_movement.meta.phone +' <br>'+
				(data.seller ? '<strong>Vendedor: </strong> ('+ data.seller.key_code +') '+ data.seller.name : ' ') +'<br>'+
	          	'<strong>Enviar a: </strong> '+ data.addressee +' '+ data.send_to+
	        '</div>'+
	        '<div style="float:left;width:65%">'+
	        	'<strong>Observaciones: </strong>'+
	          	data.inventory_movement.observations+
	        '</div>'+
	        '<div style="text-align:right;float:left;width:35%;margin: 0 0 10px 0;">'+
	          '<strong>Registró: </strong>'+
	          data.inventory_movement.username+
	        '</div>'+
			'<table style="text-align:center;width:100%">'+
			'<thead>'+
			  '<tr>'+
				  '<th>Cantidad</th>'+
				  '<th>Clave</th>'+
				  '<th>Descripción</th>'+
				  '<th>'+(data.inventory_movement.inventory_movement_type == "output" ? 'P' : 'C')+'/Unitario</th>'+
				  '<th>Importe</th>'+
			  '</tr>'+
			'</thead>'+
			'<tbody>';
			data.inventory_movement.product_movements.forEach(function(a){
			    content += '<tr>'+
			      '<td>'+a.quantity+'</td>'+
			      '<td>'+a.product_key_code+'</td>'+
			      '<td>'+a.product_description+'</td>'+
			      '<td style="text-align:right;padding-right:5px">$'+a.unit_cost+'</td>'+
			      '<td style="text-align:right;padding-right:5px;">$'+a.subtotal+'</td>'+
			    '</tr>';
			});
			content += '<tr>'+
				'<td colspan="4" style="text-align:right;padding-right:5px;font-weight:bold;">SUBTOTAL:</td>'+
				'<td style="text-align:right;padding-right:5px;">$'+data.subtotal+'</td>'+
			'</tr>'+
			'<tr><td colpan="5" style="border:0;text-align:center;color:white;">-</td></tr>'+
			'<tr>'+
				'<td colspan="4" style="text-align:right;padding-right:5px;font-weight:bold;border:0">DESC.:</td>'+
				'<td style="text-align:right;padding-right:5px;border:0">- '+data.discount+'</td>'+
			'</tr>'+
			'<tr>'+
				'<td colspan="4" style="text-align:right;padding-right:5px;font-weight:bold;border:0">IVA:</td>'+
				'<td style="text-align:right;padding-right:5px;border:0">+ '+data.iva+'</td>'+
			'</tr>'+
			'<tr>'+
			    '<td colspan="4" style="text-align:right;padding-right:5px;border:0;"><strong>TOTAL:</strong></td>'+
			    '<td style="text-align:right;padding-right:5px;border:0;"><strong>$'+data.total+'</strong></td>'+
			 '</tr>'+
			'</tbody>'+
			'</table>'+
			'<div style="float:left;width:100%;margin-top:10px">'+
			  	numeroALetras(data.total)+
			  	(data.document == "COTIZACIÓN" ? ( "<br>DOCUMENTO VALIDO HASTA "+(data.effective_date) ) : "")+
			'</div>'+
		"<div>";
		return content;
	}

	let print_purchase_btn = document.getElementById('print-purchase-btn');
	if (print_purchase_btn) {
		print_purchase_btn.addEventListener('click', ev => {
			ev.preventDefault();
			// console.log('imprimir compra');
			let route = print_purchase_btn.dataset.modelPath;
			let id = print_purchase_btn.dataset.id;
			fetch('/'+route+'/'+id+'.json')
				.then(resp => resp.json())
				.then(resp => {
					// console.log(resp);
					content = generatePurchase(resp);
					openPrint(content);
				})
				.catch(error => {
					console.log(error);
				})

		});
	}

	let generatePurchase = data => {
		//console.log("venta no nota de venta");
		let content = "<div style='width=100%'>"+
			'<div style="float:left;width:100%; text-align:center">'+
				`<h1 style="margin:0 0 10px 0">${appName}</h1>`+
			'</div>'+
	        '<div style="float:left;width:60%;">'+
	          '<strong class="titulo-formato">Domicilio:</strong><br>'+
	          'Zacualtipán de Ángeles, Hidalgo, México, CP:43200 <br>'+
	          '<strong>Lugar de expedición:</strong><br>'+
	          'Zacualtipán de Ángeles, Hidalgo, México, CP:43200'+
	        '</div>'+
	        '<div style="float:left;width:40%">'+
	          '<strong>'+data.document+ ' NO: </strong>'+data.number +'<br>'+
	          '<strong>Fecha: </strong>'+data.inventory_movement.full_date+'  <br>'+
	          '(COMPRAS) '+data.linked_to +' '+ data.inventory_movement.document+'<br>'+
	        '</div>'+
	        '<div style="float:left;width:100%;margin-top:10px">'+
	        	'<strong>Cliente: </strong>('+
	        	data.inventory_movement.meta.key_code + ') ' + data.name+
	        	'<br>'+ data.adress +' <strong>RFC: </strong>'+ data.rfc + ' ' +
	        	'<strong>Teléfono: </strong>' + data.inventory_movement.meta.phone +' <br>'+
	          	'<strong>Entregar a: </strong> '+ data.deliver_to+
	        '</div>'+
	        '<div style="float:left;width:65%">'+
	        	'<strong>Observaciones: </strong>'+
	          	data.inventory_movement.observations+
	        '</div>'+
	        '<div style="text-align:right;float:left;width:35%;margin: 0 0 10px 0;">'+
	          '<strong>Registró: </strong>'+
	          data.inventory_movement.username+
	          '<br><strong>Fecha de recepción: </strong>'+
	          data.reception_date+
	        '</div>'+
			'<table style="text-align:center;width:100%">'+
			'<thead>'+
			  '<tr>'+
				  '<th>Cantidad</th>'+
				  '<th>Clave</th>'+
				  '<th>Descripción</th>'+
				  '<th>'+(data.inventory_movement.inventory_movement_type == "output" ? 'P' : 'C')+'/Unitario</th>'+
				  '<th>Importe</th>'+
			  '</tr>'+
			'</thead>'+
			'<tbody>';
			data.inventory_movement.product_movements.forEach(function(a){
			    content += '<tr>'+
			      '<td>'+a.quantity+'</td>'+
			      '<td>'+a.product_key_code+'</td>'+
			      '<td>'+a.product_description+'</td>'+
			      '<td style="text-align:right;padding-right:5px">$'+a.unit_cost+'</td>'+
			      '<td style="text-align:right;padding-right:5px;">$'+a.subtotal+'</td>'+
			    '</tr>';
			});
			content += '<tr>'+
				'<td colspan="4" style="text-align:right;padding-right:5px;font-weight:bold;">SUBTOTAL:</td>'+
				'<td style="text-align:right;padding-right:5px;">$'+data.subtotal+'</td>'+
			'</tr>'+
			'<tr><td colpan="5" style="border:0;text-align:center;color:white;">-</td></tr>'+
			'<tr>'+
				'<td colspan="4" style="text-align:right;padding-right:5px;font-weight:bold;border:0">DESC.:</td>'+
				'<td style="text-align:right;padding-right:5px;border:0">- '+data.discount+'</td>'+
			'</tr>'+
			'<tr>'+
				'<td colspan="4" style="text-align:right;padding-right:5px;font-weight:bold;border:0">IVA:</td>'+
				'<td style="text-align:right;padding-right:5px;border:0">+ '+data.iva+'</td>'+
			'</tr>'+
			'<tr>'+
			    '<td colspan="4" style="text-align:right;padding-right:5px;border:0;"><strong>TOTAL:</strong></td>'+
			    '<td style="text-align:right;padding-right:5px;border:0;"><strong>$'+data.total+'</strong></td>'+
			 '</tr>'+
			// '<tr>'+
			//     '<td colspan="4" style="text-align:right;padding-right:5px;border:0;"><strong>GASTOS INDIRECTOS:</strong></td>'+
			//     '<td style="text-align:right;padding-right:5px;border:0;"><strong>$'+data.gastos_indirectos+'</strong></td>'+
			//  '</tr>'+
			'</tbody>'+
			'</table>'+
			'<div style="float:left;width:100%;margin-top:10px">'+
			  	numeroALetras(data.total)+
			'</div>'+
		"<div>";
		return content;
	}

	let print_inventory_movement_btn = document.getElementById('print-inventory_movement-btn');
	if (print_inventory_movement_btn) {
		print_inventory_movement_btn.addEventListener('click', ev => {
			ev.preventDefault();
			// console.log('imprimir movimiento al inventario');
			let route = print_inventory_movement_btn.dataset.modelPath;
			let id = print_inventory_movement_btn.dataset.id;
			fetch('/'+route+'/'+id+'.json')
				.then(resp => resp.json())
				.then(resp => {
					// console.log(resp);
					content = printInventoryMovement(resp);
					openPrint(content);
				}).catch(error => {
					console.log(error);
				})

		});
	}

	let printInventoryMovement = data => {
		// console.log(data);
		let content = "<div style='width=100%'>"+
            '<div style="float:left;width:60%; text-align:center">'+
              `<strong class="titulo-formato">${appName}<br>ZACUALTIPÁN DE ÁNGELES, HGO. CP: 43200</strong><br>`+
              '<strong>Movimiento al Inventario</strong><br>'+
              '<strong>('+(data.inventory_movement_type == 'input' ? 'ENTRADA' : 'SALIDA')+') '+data.concept_code_key+' '+data.concept_description+'</strong> <br>'+
            '</div>'+
            '<div style="float:left;width:40%">'+
              '<strong>FOLIO: </strong>'+data.folio +'<br>'+
              '<strong>Fecha: </strong>'+data.full_date+'  <br>'+
              '<strong>Documento: </strong>'+data.document+'<br>'+
              '<strong>Almacén: </strong>'+data.warehouse.description +'<br>'+
            '</div>'+
            '<div style="text-align:center;float:left;width:50%;margin: 15px 0 20px 0;">'+
              '<strong>Empleado: </strong>'+
              data.username+
            '</div>'+
            '<div style="text-align:center;float:left;width:50%;margin: 15px 0 20px 0;">'+
              (data.inventory_movement_type == "output" ? '<strong>Cliente:</strong> ' : '<strong>Proveedor:</strong> ')+
              data.meta.name+
            '</div>'+
			'<table style="text-align:center;width:100%">'+
			'<thead>'+
			  '<th>Cantidad</th>'+
			  '<th>Clave</th>'+
			  '<th>Descripción</th>'+
			  '<th>'+(data.inventory_movement_type == "output" ? 'P' : 'C')+'/Unitario</th>'+
			  '<th>Subtotal</th>'+
			'</thead>'+
			'<tbody>';
			data.product_movements.forEach(function(a){
			    content += '<tr>'+
			      '<td>'+a.quantity+'</td>'+
			      '<td>'+a.product_key_code+'</td>'+
			      '<td>'+a.product_description+'</td>'+
			      '<td style="text-align:right">$'+a.unit_cost+'</td>'+
			      '<td style="text-align:right">$'+a.subtotal+'</td>'+
			    '</tr>';
			});
			content += '<tr>'+
			    '<td colspan="3"></td>'+
			    '<td style="text-align:right"><strong>TOTAL:</strong></td>'+
			    '<td style="text-align:right"><strong>$'+data.total+'</strong></td>'+
			  '</tr>'+
			  '<tr>'+
			  	'<td colspan="5" style="text-align:center">'+
			  		numeroALetras(data.total)+
			  	'</td>'+
			  '</tr>'+
			'</tbody>'+
			'</table>'+
			'<div style="float:left;width:100%;margin-top:15px">'+
              '<strong>Observaciones:</strong>'+
              data.observations+
            '</div>'+
		"<div>";
		return content;
	}
	
	openPrint = content => {
		var myWindow = window.open('', 'Imprimir Documento', 'height=650,width=1000');
	    myWindow.document.write('<html><head><title>Inventarios</title>');
	    myWindow.document.write('</head><body>');
	    myWindow.document.write('<style>table{text-align:center;border-collapse: collapse} table thead tr td, table thead tr th, table tbody tr td {border:1px solid gray;}table thead tr th{border:1px solid gray;}</style>');
	    //console.log(content);
	    myWindow.document.write(content);
	    myWindow.document.write('</body></html>');
	    myWindow.document.close(); // necessary for IE >= 10
	    myWindow.focus(); // necessary for IE >= 10
	    myWindow.print();
	    myWindow.close();
	    myWindow.onload=function(){ // necessary if the div contain images
	        myWindow.focus(); // necessary for IE >= 10
	        myWindow.print();
	        myWindow.close();
	    };
	}

});

numeroALetras = (function() {
    // Código basado en el comentario de @sapienman
    // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
    function Unidades(num) {

        switch (num) {
            case 1:
                return 'UN';
            case 2:
                return 'DOS';
            case 3:
                return 'TRES';
            case 4:
                return 'CUATRO';
            case 5:
                return 'CINCO';
            case 6:
                return 'SEIS';
            case 7:
                return 'SIETE';
            case 8:
                return 'OCHO';
            case 9:
                return 'NUEVE';
        }

        return '';
    } //Unidades()

    function Decenas(num) {

        var decena = Math.floor(num / 10);
        var unidad = num - (decena * 10);

        switch (decena) {
            case 1:
                switch (unidad) {
                    case 0:
                        return 'DIEZ';
                    case 1:
                        return 'ONCE';
                    case 2:
                        return 'DOCE';
                    case 3:
                        return 'TRECE';
                    case 4:
                        return 'CATORCE';
                    case 5:
                        return 'QUINCE';
                    default:
                        return 'DIECI' + Unidades(unidad);
                }
            case 2:
                switch (unidad) {
                    case 0:
                        return 'VEINTE';
                    default:
                        return 'VEINTI' + Unidades(unidad);
                }
            case 3:
                return DecenasY('TREINTA', unidad);
            case 4:
                return DecenasY('CUARENTA', unidad);
            case 5:
                return DecenasY('CINCUENTA', unidad);
            case 6:
                return DecenasY('SESENTA', unidad);
            case 7:
                return DecenasY('SETENTA', unidad);
            case 8:
                return DecenasY('OCHENTA', unidad);
            case 9:
                return DecenasY('NOVENTA', unidad);
            case 0:
                return Unidades(unidad);
        }
    } //Unidades()

    function DecenasY(strSin, numUnidades) {
        if (numUnidades > 0)
            return strSin + ' Y ' + Unidades(numUnidades)

        return strSin;
    } //DecenasY()

    function Centenas(num) {
        var centenas = Math.floor(num / 100);
        var decenas = num - (centenas * 100);

        switch (centenas) {
            case 1:
                if (decenas > 0)
                    return 'CIENTO ' + Decenas(decenas);
                return 'CIEN';
            case 2:
                return 'DOSCIENTOS ' + Decenas(decenas);
            case 3:
                return 'TRESCIENTOS ' + Decenas(decenas);
            case 4:
                return 'CUATROCIENTOS ' + Decenas(decenas);
            case 5:
                return 'QUINIENTOS ' + Decenas(decenas);
            case 6:
                return 'SEISCIENTOS ' + Decenas(decenas);
            case 7:
                return 'SETECIENTOS ' + Decenas(decenas);
            case 8:
                return 'OCHOCIENTOS ' + Decenas(decenas);
            case 9:
                return 'NOVECIENTOS ' + Decenas(decenas);
        }

        return Decenas(decenas);
    } //Centenas()

    function Seccion(num, divisor, strSingular, strPlural) {
        var millones = Math.floor(num / divisor)
        var resto = num - (millones * divisor)

        var letras = '';

        if (millones > 0)
            if (millones > 1)
                letras = Millones(millones) + ' ' + strPlural;
            else
                letras = strSingular;

        if (resto > 0)
            letras += '';

        return letras;
    } //Seccion()

    function Miles(num) {
        var divisor = 1000;
        var cientos = Math.floor(num / divisor)
        var resto = num - (cientos * divisor)

        var strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
        var strCentenas = Centenas(resto);

        if (strMiles == '')
            return strCentenas;

        return strMiles + ' ' + strCentenas;
    } //Miles()

    function Millones(num) {
        var divisor = 1000000;
        var cientos = Math.floor(num / divisor)
        var resto = num - (cientos * divisor)

        var strMillones = Seccion(num, divisor, millon(num, true), millon(num, false));
        var strMiles = Miles(resto);

        if (strMillones == '')
            return strMiles;

        return strMillones + ' ' + strMiles;
    } //Millones()

    function millon(num, singular) {
        var letraMillon = '';
        if (singular == true)
            letraMillon = 'UN MILLON';
        else
            letraMillon = 'MILLONES'
        if (num % 1000000 == 0)
            letraMillon = letraMillon + ' DE'
        return letraMillon;
    }

    function Billones(num) {
        var divisor = 1000000000000;
        var millones = Math.floor(num / divisor)
        var resto = num - (millones * divisor)

        if (millones >= 1000000) 
            return "IGUAL O MAYOR A UN TRILLÓN DE";

        var strBillones = Seccion(num, divisor, billon(num, true), billon(num, false));
        var strMillones = Millones(resto);

        if (strBillones == '')
            return strMillones;

        return strBillones + ' ' + strMillones;
    } //Billones()

    function billon(num, singular) {
        var letraBillon = '';
        if (singular == true)
            letraBillon = 'UN BILLON';
        else
            letraBillon = 'BILLONES'
        if (num % 1000000000000 == 0)
            letraBillon = letraBillon + ' DE'
        return letraBillon;
    }


    return function NumeroALetras(num, currency) {
        currency = currency || {};
        var data = {
            numero: num,
            enteros: Math.floor(num),
            centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
            letrasCentavos: '',
            letrasMonedaPlural: currency.plural || 'PESOS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
            letrasMonedaSingular: currency.singular || 'PESO', //'PESO', 'Dólar', 'Bolivar', 'etc'
            letrasMonedaCentavoPlural: currency.centPlural || 'CENTAVOS',
            letrasMonedaCentavoSingular: currency.centSingular || 'CENTAVO'
        };

        if (data.centavos > 0) {
            data.letrasCentavos = 'CON ' + (function() {
                if (data.centavos == 1)
                    return Billones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
                else
                    return Billones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
            })();
        };

        if (data.enteros == 0)
            return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
        if (data.enteros == 1)
            return Billones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos + " MXN";
        else
            return Billones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos + " MXN";
    };

})();