// Bootstrap -->
require("./basik/libs/bootstrap.min.js")
// ajax page -->\ TODO: revisar si sepueden eliminar completamente
//window.Pjax = require("./basik/libs/pjax/pjax.min.js")
// require("./basik/ajax.js")
// lazyload plugin -->
MODULE_CONFIG = require("./basik/lazyload.config.js")
MODULE_OPTION_CONFIG = require("./basik/lazyload.config.js")
require("./basik/lazyload.js")
require("./basik/plugin.js")
// scrollreveal -->
window.ScrollReveal = require("./basik/libs/scrollreveal/dist/scrollreveal.min.js");
// feathericon -->
require("./basik/libs/feather-icons/dist/feather.min.js")
require("./basik/plugins/feathericon.js")
// theme -->
require('./basik/theme.js')
require('./basik/utils.js')

require('./basik/libs/jquery-fullscreen-plugin/jquery.fullscreen-min.js')
require("./basik/plugins/fullscreen.js")
require("./basik/plugins/jquery.toast.min.js")

window.ApexCharts = require('./basik/libs/apexcharts/dist/apexcharts.min.js')
// Aqui hay ejemplos de graficas
// require("./basik/plugins/apexcharts.js")