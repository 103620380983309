const { ajaxSetup } = require("jquery");

document.addEventListener("turbolinks:load", () => {
	console.log('hola desde batches.js');

	// para el index
	let btn_open_modal_import = document.getElementById('import-batches-btn');
	if (btn_open_modal_import) {
		// console.log(btn_open_modal_import)
		btn_open_modal_import.addEventListener('click', ev => {
			$('#modal-import-batches').modal('show');
		});

	}

	let batch_style_select = document.getElementById('batch_style-select');
	if(batch_style_select) {
		// quita callbacks por turbolinks
		$(document).off("change","#batch_style-select");
		// añade el evento a los campos cuando cambian de valor para recalcular
		$(document).on('change', '#batch_style-select', function(event) {
			const models_route = $("#batch_style-select").data('route')
			const selected_style = event.target.value;
			const container = document.getElementById('areas');
			container.innerHTML = "";
			if (selected_style) {
				fetch("/"+models_route+"/by_style.json?style="+selected_style)
					.then(response => response.json())
					.then(response => {
						if (response) {
							document.getElementById("batch_description").value = response.description
							$('#batch_process').val(response.washing_process).trigger('change');
							response.areas.forEach((area)=>{
								// console.log(area);
								fetch("/area_managers/by_area.json?area="+area)
									.then(response => response.json())
									.then(response => { 
										// console.log(response);
										const link = document.querySelector('.links a.add_fields');
            							link.click();

            							// Obtener el último campo agregado
            							const newField = container.querySelector('.nested-fields:last-child');

            							// Configurar los campos con los datos obtenidos
            							const areaLabel = newField.querySelector('label.area-manager-label');
            							areaLabel.textContent = area;

            							newField.querySelector('input[name*="[area]"]').value = area;

            							const select = newField.querySelector('select[name*="[area_manager]"]');
										const defaultOption = document.createElement('option');
            							defaultOption.value = null;
            							defaultOption.textContent = "Selecciona encargado";
										select.insertBefore(defaultOption, select.firstChild);
										
            							response.forEach(option => {
            							  const optionElement = document.createElement('option');
            							  optionElement.value = option.alias;
            							  optionElement.textContent = option.alias;
            							  select.appendChild(optionElement);
            							});
										$(select).select2();
									})
							})
						}
				})
			}
		});
	}

	let table_batches = document.getElementById('batches');
	// si existe tabla de batches
	if (table_batches) {
		addBatchExpenseCallbacks = () => {
			let add_expense_btns = document.querySelectorAll('[data-type="add-batch_expense"]');
			if (add_expense_btns.length > 0) {
				add_expense_btns.forEach(el => {
					el.addEventListener('click', ev => {
						let id = el.dataset.id;
						let description = el.dataset.description;
						$("#addExpenseModal").modal("show");
						document.getElementById('batch_id-description').value = description;
						document.getElementById('batch_id-field').value = id;
					});
				});
			}
		}

		changeStatusBatchCallbacks = () => {
			let change_status_btns = document.querySelectorAll('[data-type="change-status-batch-btn"]');
			if (change_status_btns.length > 0) {
				change_status_btns.forEach(el => {
					el.addEventListener('click', ev => {
						let route = el.dataset.route;
						let id = el.dataset.id;
						let status_now = el.dataset.now;
						let description = el.dataset.description;
						let content = '<div class="row"><form class="col-md-12" id="update_status" action="/' + route + '/' + id + '" accept-charset="UTF-8" data-remote="true" method="post">' +
							'<input type="hidden" name="_method" value="patch">' +
							'<div class="field">' +
							'<label for="batch_status">Estado</label>' +
							'<select name="batch[status]" class="form-control">' +
							'<option value="finished" ' + ((status_now == 'finished') ? 'selected' : '') + '>Terminado</option>' +
							'<option value="in_process" ' + ((status_now == 'in_process') ? 'selected' : '') + '>En progreso</option>' +
							'</select>' +
							'</div>' +
							'</form></div>';
						let btns = '<div class="actions">' +
							'<input form="update_status" class="btn btn-primary" type="submit" name="commit" value="Actualizar estado" data-disable-with="espera...">' +
							'</div>';
						document.querySelector("#sharedModal .modal-title").innerHTML = "Cambiar estado corte " + description;
						document.querySelector("#sharedModal .modal-body").innerHTML = content;
						document.querySelector("#sharedModal .modal-footer").innerHTML = btns;
						$("#sharedModal").modal("show");
					});
				});
			}
		}
		addBatchExpenseCallbacks();
		changeStatusBatchCallbacks();
	}


	// Gastos de cortes
	let form_add_expenses = document.getElementById('add-batch-fields-form');
	if (form_add_expenses) {
		let calculateTotal = input => {
			// console.log(input)
			let parent = input.closest('.nested-fields');
			let type_field = parent.querySelector('[data-type="select_expense_type"]');
			let amount_field = parent.querySelector('[data-type="field_amount"]');
			let total_field = parent.querySelector('[data-type="field_total"]');
			let pieces_field = parent.querySelector('[data-type="field_pieces"]');

			switch (type_field.value) {
				case 'general':
					pieces_field.classList.add('d-none');
					pieces_field.value = "0";
					total_field.value = parseFloat(amount_field.value);
					break;
				case 'por_pieza':
					let batch_quantity = document.getElementById('batch_quantity').value;
					pieces_field.classList.remove('d-none');
					if (pieces_field.value == "0" || pieces_field.value == "0.0"){
						pieces_field.value = batch_quantity
					}
					total_field.value = parseFloat(pieces_field.value || 0) * parseFloat(amount_field.value);
					pieces_field.setAttribute("max", batch_quantity);
					break;
				case 'materia_prima':
					pieces_field.classList.remove('d-none');
					total_field.value = parseFloat(pieces_field.value || 0) * parseFloat(amount_field.value);
					pieces_field.removeAttribute("max");
					break;
			}
			calculateGeneralTotal();
		}

		let calculateGeneralTotal = () => {
			let total = 0;
			document.querySelectorAll('[data-type="field_total"]').forEach(function (element, index) {
				if ($(element).is(":visible")) {
					total += parseFloat(element.value);
				}
			});
			let total_amounts_fields = document.getElementById('total-amounts');
			if (total_amounts_fields) { total_amounts_fields.value = total; }
		}

		let addCallbacksCalculateTotal = () => {
			let inputs_calculate_total = document.querySelectorAll('[data-action="calculate-total"]');
			inputs_calculate_total.forEach(el => {
				el.addEventListener('change', ev => {
					calculateTotal(el);
				});
			});
		}

		let addConcurrentExpenses = () => {
			let elem = document.getElementById('addConcurrentExpensesBtn');
			let route_expenses = elem.dataset.route;
			fetch(route_expenses + '.json')
				.then(resp => resp.json())
				.then(elems => {
					let options = '';
					elems.forEach(el => {
						options += '<option value="' + el.id + '">' + el.description + ' - $' + el.quantity + ' - ' + el.expense_type + '</option>'
					});
					let routeAdd = elem.dataset.routeAdd;
					let content = '<div class="row"><form class="col-md-12" id="addConcurrentExpenses" action="' + routeAdd + '" accept-charset="UTF-8" data-remote="true" method="get">' +
						'<div class="field"><select name="ids[]" multiple="multiple" class="form-control">' + options + '</select></div>';

					let btns = '<div class="actions">' +
						'<input class="btn btn-primary" form="addConcurrentExpenses" type="submit" name="commit" value="Agregar" data-disable-with="espera...">' +
						'</div>';
					document.querySelector("#sharedModal .modal-title").innerHTML = 'Agregar desde gastos concurrentes';
					document.querySelector("#sharedModal .modal-body").innerHTML = content;
					document.querySelector("#sharedModal .modal-footer").innerHTML = btns;
					$("#sharedModal").modal("show");
				});
		}

		$('#batch_expenses').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
			addCallbacksCalculateTotal();
		});
		$('#batch_expenses').on('cocoon:after-remove', function (e, removedItem, originalEvent) {
			calculateGeneralTotal();
		});

		if (document.querySelector('batch_expenses')) {
			observeChangesOnNode('batch_expenses', jqueryElement => {
				addCallbacksCalculateTotal();
			});
		}

		addCallbacksCalculateTotal();

		let btnAddExpense = document.getElementById('addConcurrentExpensesBtn');
		if (btnAddExpense) {
			btnAddExpense.addEventListener('click', addConcurrentExpenses);
		}
	}

	// ------------------------------------------------
	// ---------------------- Para formulario de cortes
	let batchForm = document.getElementById('batch-form');
	if (batchForm) {
		// al entrar al form llamar las funciones
		updateMatixStructue("general");

		$('#sizes-list').on('cocoon:after-insert', function (e, insertedItem) {
			updateMatixStructue("insert", "sizes", insertedItem);
		});
		$('#sizes-list').on('cocoon:after-remove', function (e, removedItem) {
			// recalcular al eliminar
			updateMatixStructue("remove", "sizes", removedItem);
		});

		$('#colors-list').on('cocoon:after-insert', function (e, insertedItem) {
			updateMatixStructue("insert", "colors", insertedItem);
		});
		$('#colors-list').on('cocoon:after-remove', function (e, removedItem) {
			// recalcular al eliminar
			updateMatixStructue("remove", "colors", removedItem);
		});

		function addCallbacksToCalculateTotals() {
			// quita callbacks por turbolinks
			$(document).off("change", ".calculate-size-color");
			// añade el evento a los campos cuando cambian de valor para recalcular
			$(document).on('change', '.calculate-size-color', function () {
				calculateXYTotals();
			});
		}

		// operation (insert y remove), name_nested (sizes y colors), y div es el elemento insertado o eliminado
		// es llamada cada vez que se insertan/eliminan tallas/colores
		function updateMatixStructue(operation, name_nested, div) {//para agreagar los tds intermedios de XY en la tabla
			switch (operation) {
				case "insert":
					// Al insertar elementos se le pone el index
					setIndexesToSizesAndColors();
					if (name_nested == "sizes") {
						// cuando se insertan tallas, da vueltas en colores y a cada tr le inserta un td por cada talla 
						$('[data-tipo="color"]').each(function (index, color) {
							if ($(color).is(':visible')) {
								// Obtiene el index del color y el de la talla insertada
								const index_x = $(color).attr("data-index");
								const index_y = $(div).attr("data-index");
								$(color).append(buildMatrixField(index_x, index_y));
							}
						})
					} else if (name_nested == "colors") {
						// cuando se insertan colores se le insertan los td por cada talla
						$('[data-tipo="talla"]').each(function (index, size) {
							if ($(size).is(':visible')) {
								// Obtiene el index de la talla y del color insertado
								const index_x = $(div).attr("data-index");
								const index_y = $(size).attr("data-index");
								$(div).append(buildMatrixField(index_x, index_y));
							}
						})
					}
					break;
				case "remove":
					$("[data-tipo='matriz_eliminar']").hide();
					if (name_nested == "sizes") {
						// cuando se eliminan tallas se elimina el campo por cada color
						$('[data-tipo="color"]').each(function (index, elem) {
							// Busca los td de la matriz a borrar por cada color los que coincidan con el index de la talla eliminada, los oculta
							// y le pone el campo _destroy en true para que se elimine de la BD
							var index_x = $(elem).attr("data-index");
							var index_y = $(div).attr("data-index");
							// console.log(index_x + "-" + index_y)
							$("[data-x='" + index_x + "'][data-y='" + index_y + "'][data-tipo='matrix_field'] [data-tipo='destruir']").val("true");
							// console.log($("[data-x='"+index_x+"'][data-y='"+index_y+"'][data-tipo='matrix_field']").length)
							$("[data-x='" + index_x + "'][data-y='" + index_y + "'][data-tipo='matrix_field']").css('display', 'none').attr('data-x', '').attr('data-y', '').attr({ 'data-x': '', 'data-y': '' });
						});
					} else if (name_nested == "colors") {
						// Busca los td de la matriz a borrar que coincidan con el index del color eliminado, los oculta
						// y le pone el campo _destroy en true para que se elimine de la BD
						var index_x = $(div).attr("data-index");
						$("[data-x='" + index_x + "'][data-tipo='matrix_field'] [data-tipo='destruir']").val("true");
						// console.log($("[data-x='"+index_x+"'][data-tipo='matrix_field']").length)
						$("[data-x='" + index_x + "'][data-tipo='matrix_field']").css('display', 'none').attr('data-x', '').attr('data-y', '').attr({ 'data-x': '', 'data-y': '' });
					}
					// Se restablecen los index y los campos 'x' y 'y' en cada matrix_field
					reestoreIndex();
					$("[data-tipo='matriz_eliminar']").show();
					break;
				case "general":
					// cuando carga por primera vez, sirve cuando es pedido editado se le ponen los index a 
					// cada color y talla
					setIndexesToSizesAndColors();

					// acomoda los matriz fields en orden en la tabla
					$('[data-tipo="color"]:visible').each(function (index, elem) {
						// console.log(elem)
						const ind = $(elem).attr("data-index");
						$('[data-tipo="matrix_field"][data-x="' + ind + '"]').each(function (index, m) {
							$(elem).append(m);
						});
					});

					break;
			}
			// Calcular los totales o recalcular matriz si esta activado el campo proporciones
			calculateXYTotals();
			addCallbacksToCalculateTotals();
		}

		function buildMatrixField(index_x, index_y) {
			const nuevo_id = (new Date().getTime() + '' + index_x + '' + index_y);

			return '<td data-tipo="matrix_field" data-x="' + index_x + '" data-y="' + index_y + '">' +
				'<input type="hidden" name="batch[batch_matrix_fields_attributes][' + nuevo_id + '][_destroy]" id="batch_batch_matrix_fields_attributes_' + nuevo_id + '__destroy" value="false" data-tipo="destruir">' +
				'<input type="hidden" name="batch[batch_matrix_fields_attributes][' + nuevo_id + '][x]" id="batch_batch_matrix_fields_attributes_' + nuevo_id + '_x" value="' + index_x + '" data-tipo="matriz_x">' +
				'<input type="hidden" name="batch[batch_matrix_fields_attributes][' + nuevo_id + '][y]" id="batch_batch_matrix_fields_attributes_' + nuevo_id + '_y" value="' + index_y + '" data-tipo="matriz_y">' +
				'<div class="field">' +
				'<label for="batch_batch_matrix_fields_attributes_' + nuevo_id + '_quantity" class="active col-form-label">Cantidad</label>' +
				'<input type="number" name="batch[batch_matrix_fields_attributes][' + nuevo_id + '][quantity]" id="batch_batch_matrix_fields_attributes_' + nuevo_id + '_quantity" data-tipo="quantity" class="calculate-size-color form-control" value="0">' +
				'</div>' +
				'<div class="field">' +
				'<label for="batch_batch_matrix_fields_attributes_' + nuevo_id + '_bultos" class="active col-form-label">Bultos</label>' +
				'<input type="number" name="batch[batch_matrix_fields_attributes][' + nuevo_id + '][bultos]" id="batch_batch_matrix_fields_attributes_' + nuevo_id + '_bultos" data-tipo="bultos" class="calculate-size-color form-control" value="0">' +
				'</div>' +
				'</td>';
		}

		// Poner los index a los colores tallas, se llama desde general  e insertar elementos
		function setIndexesToSizesAndColors() {
			$('[data-tipo="talla"]:visible').each(function (index, elem) {
				// console.log(elem)
				$(elem).attr("data-index", index);
			});
			$('[data-tipo="color"]:visible').each(function (index, elem) {
				// console.log(elem)
				$(elem).attr("data-index", index);
			});
		}

		// Cambiar los index cuando se elimina una talla o color, se llama al eliminar talla o color
		function reestoreIndex() {
			$('[data-tipo="talla"]:visible').each(function (index, elem) {
				// console.log(elem)
				var anterior = $(elem).attr("data-index");
				$(elem).attr("data-index", index);
				// console.log($("[data-tipo='matrix_field'][data-y='"+anterior+"']")); 
				$("[data-tipo='matrix_field'][data-y='" + anterior + "'] [data-tipo='matriz_y']").val(index);
				$("[data-tipo='matrix_field'][data-y='" + anterior + "']").attr('data-y', index);
			});
			$('[data-tipo="color"]:visible').each(function (index, elem) {
				// console.log(elem)
				var anterior = $(elem).attr("data-index");
				$(elem).attr("data-index", index);
				// console.log($("[data-tipo='matrix_field'][data-x='"+anterior+"']")); 
				$("[data-tipo='matrix_field'][data-x='" + anterior + "'] [data-tipo='matriz_x']").val(index);
				$("[data-tipo='matrix_field'][data-x='" + anterior + "']").attr('data-x', index);
			});
		}

		// es llamada cada vez que se elimina o inserta una talla/color, al cargar la pagina 
		// desde calcularTallaColor() y cuando cambia alguna cantidad de la matriz
		function calculateXYTotals() { // Totales de tallas y totales de colores
			var totalxy = 0;
			var totalxy_bultos = 0;
			$("#totales-tallas").remove();
			$(".totales").remove();
			$("#sizes-list").append("<td class='totales center-align'>Totales</td>");
			$("[data-tipo='color']:visible").each(function (index_color, color) {
				var total_color = 0;
				var total_bultos_color = 0;
				// si esta visible en el dom
				$(color).find("[data-tipo='matrix_field']").each(function (index_td, td) {
					// console.log($(td))
					total_color += parseFloat($(td).find("[data-tipo='quantity']").val() || 0);
					total_bultos_color += parseFloat($(td).find("[data-tipo='bultos']").val() || 0);
				})
				$(color).append("<td class='totales center-align'>Piezas: " + total_color + "<br>Bultos: " + total_bultos_color + "</td>");
				totalxy += total_color;
				totalxy_bultos += total_bultos_color;
				// console.log(total_color);
			});

			$("#colors-list").append("<tr id='totales-tallas'><td class='right-align totales'>Totales</td></tr>");
			$("[data-tipo='talla']:visible").each(function (index_talla, talla) {
				var total_talla = 0;
				var total_bultos_talla = 0;
				// si esta visible en el dom
				$("[data-tipo='color']:visible").each(function (index_color, color) {
					// si esta visible en el dom
					// encontrar todos los totales de la talla
					var id = "[data-tipo='matrix_field'][data-x='" + $(color).attr('data-index') + "'][data-y='" + $(talla).attr('data-index') + "']";
					total_talla += parseFloat($(id).find("[data-tipo='quantity']").val() || 0);
					total_bultos_talla += parseFloat($(id).find("[data-tipo='bultos']").val() || 0);
				});
				$("#totales-tallas").append("<td class='totales center-align'>Piezas: " + total_talla + "<br>Bultos: " + total_bultos_talla + "</td>");
				// console.log(total_talla)
			});
			$("#totales-tallas").append("<td class='totales center-align' style='text-decoration:underline'>Piezas: " + totalxy + "<br>Bultos: " + totalxy_bultos + "</td>");
		}
	}

	// quita callbacks por turbolinks
	$(document).off("change", "#batch_order_id-select2");
	// añade el evento a los campos cuando cambian de valor para recalcular
	$(document).on('change', '#batch_order_id-select2', function (event) {
		const orders_route = $("#batch_order_id-select2").data('route')
		const selected_order = event.target.value || 0
		const idFields = [
			"batch_consumption", "batch_description", "batch_business_name", "batch_expiration_date",
			"batch_order","batch_format", "batch_size"
		  ];
		let batch_fabric = document.getElementById("batch_fabric")
		let batch_client = document.getElementById("batch_client_name")
		var selectFields = [batch_style_select, batch_fabric, batch_client, batch_process];
		const container = document.getElementById('areas');
		container.innerHTML = "";
		if (selected_order != 0) {
			fetch(`/${orders_route}/${selected_order}/get_single`)
				.then(response => response.json())
				.then(response => {
					var existingOption = batch_fabric.querySelector("option[value='" + response.cloth + "']");
					if (!existingOption){
						var newOption = document.createElement("option");
						newOption.value = response.cloth; 
						newOption.textContent =response.cloth; 
						batch_fabric.appendChild(newOption);
					}
					batch_fabric.value = response.cloth
					batch_client.value = response.store;
					batch_style_select.value = response.style
					selectFields.forEach( (field) =>{
						$(field).trigger("change")
						field.setAttribute('style', 'pointer-events:none');
					})
					document.getElementById("batch_order").value = response.oc
					document.getElementById("batch_format").value = response.format
					document.getElementById("batch_consumption").value = response.consumption
					document.getElementById("batch_description").value = response.description
					document.getElementById("batch_business_name").value = response.business_name
					document.getElementById("batch_size").value = response.size
					document.getElementById("batch_expiration_date").value = response.cancellation_date
					idFields.forEach((id, index) => {
						if (index != 7 && index !=8) {
							const field = document.getElementById(id);
							field.readOnly = true;
						}
					})
				})
			}else {
				idFields.forEach((id, index) => {
					const field = document.getElementById(id);
					if (index != 7 && index !=8) {
						field.readOnly = false;
					}
					field.value = "";
				})
				selectFields.forEach( (field) =>{
					field.value = ""
					$(field).trigger("change")
					field.removeAttribute('style', 'pointer-events:none');
				})
				while (batch_fabric.firstChild) {
					batch_fabric.removeChild(batch_fabric.firstChild);
				}				
		}
});
});
