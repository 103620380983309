document.addEventListener("turbolinks:load", () => {
	console.log('hola desde accounts.js');

	let concept_account_select_field = document.getElementById("concept_id-account");

	if (concept_account_select_field) { // está en form de cuentas

		let changeClientProviderAccount = () => {
			let account_subject_field = document.getElementById("meta_id_account-field");
			let option_subject_selected = account_subject_field.options[account_subject_field.selectedIndex];
			let account_subject_value = account_subject_field.value; 
			if (document.getElementById("seller_id-account-field")) { 
				document.getElementById("seller_id-account-field").value = account_subject_value;
				document.getElementById("seller_id-account-field").dispatchEvent(new Event("change"));
			} 
			//fecha de vencimiento
			let expiration_date = new Date();
			let credit_days = parseInt(option_subject_selected.dataset.creditDays || 0);
			// console.log(credit_days)
			// console.log(expiration_date)
			expiration_date = new Date(expiration_date.setDate(expiration_date.getDate() + credit_days));
			// console.log(expiration_date)
				expiration_date = expiration_date.getFullYear() + "-" + ("0"+(expiration_date.getMonth()+1)).slice(-2) + "-" + ("0" + expiration_date.getDate()).slice(-2);			// console.log(expiration_date)
			document.getElementById("expiration_date_account-field").value = expiration_date;
		}

		let changeConceptAccount = () => {
			let option_selected = concept_account_select_field.options[concept_account_select_field.selectedIndex];
			// console.log(option_selected.dataset)
			let value_select = concept_account_select_field.value; 
			document.getElementById("concept_description_account-field").value = option_selected.dataset.description;
			document.getElementById("concept_transaction_account-field").value = option_selected.dataset.conceptType;
			// console.log(option_selected.dataset.referenced);
			cleanReferencedFields();
		}

		let cleanReferencedFields = () => {
			let option_selected = concept_account_select_field.options[concept_account_select_field.selectedIndex];
			document.getElementById("reference_id_account-field").value = "";
			document.getElementById("reference_no_account-field").value = "";
			document.getElementById("concept_reference_account-field").value = "";
			let referenced = option_selected.dataset.referenced;
			// console.log(option_selected.dataset)
			if (referenced == "true"){
				// console.log("si")
				document.getElementById("select-account-referenced-btn").classList.remove('d-none');
			}else {
				// console.log("no")
				document.getElementById("select-account-referenced-btn").classList.add('d-none');
			}
		}
		//cambiar la referencia de cuentas por cobrar/pagar
		let openOptionsPossibleRefsParent = () => {
			let account_type_account_value = document.getElementById("account_type_account-field").value; //si es c cobrar/pagar
			let option_selected = concept_account_select_field.options[concept_account_select_field.selectedIndex];
			let referenced = option_selected.dataset.referenced;			
			let meta_type = document.getElementById("meta_type_account-field").value;
			let meta_id = document.getElementById("meta_id_account-field").value;
			// console.log(concept_transaction_type);
			// dejar referencia como al cargar la pagina
			cleanReferencedFields();
			// console.log(referenced)
			if (referenced == "true" && meta_id && meta_type) {
				// $(".loader").show();
				// si no existe la tabla en el modal la agrega
				let search_term_field = document.getElementById("search_parent_document-field");
				let model_path = document.getElementById("account_type_account-field").dataset.modelPath;
				// $(".loader").show();
				fetch("/"+model_path+"/possible_refs_parent?account_type="+account_type_account_value+"&&meta_type="+meta_type+"&&meta_id="+meta_id+(search_term_field ? ("&search_term=" + search_term_field.value) : ""))
					.then(resp => resp.json())
					.then(resp => {
						console.log(resp);
						let options = "";
						resp.forEach((doc) => {
							options += "<tr>"+
								"<td>"+doc.concept_description+"</td>"+
								"<td>"+doc.no_document+"</td>"+
								"<td>"+doc.charge_number+"</td>"+
								"<td>"+doc.application_date+"</td>"+
								"<td>"+doc.expiration_date+"</td>"+
								"<td>$"+doc.total+"</td>"+
								"<td>"+doc.invoice+"</td>"+
								"<td>"+
									"<span class='btn btn-sm btn-success white-text' data-id='"+doc.id+"' data-type='select-parent-linked-to-field' title='Seleccionar'>+</span>"+
								"</td>"+
							"</tr>";
						});

						let modal_title = "Seleccionar referencia a cuenta existente";
						let modal_content = ""
						modal_content = "<div class='row'>"+
					      '<div class="field col-md-12"><input form="undefined" class="form-control" type="text" id="search_parent_document-field", value="'+(search_term_field ? search_term_field.value : "")+'""></div>'+
								"<div class='col-md-12 table-responsive'>"+
									"<table class='table'>"+
										"<thead><tr><th>Concepto</th><th>No. Doc.</th><th>No. cargo</th><th>Fecha Apl.</th><th>Fecha Venc.</th><th>Saldo</th><th>Folio</th><th></th></tr></thead>"+
										"<tbody>"+options+"</tbody>"+
									"</table>"+
								"</div>";

						document.querySelector("#sharedModal .modal-title").innerHTML = modal_title;
						document.querySelector("#sharedModal .modal-body").innerHTML = modal_content;
						document.querySelector("#sharedModal .modal-footer").innerHTML = '<button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>';
						
						// input para buscar	
						document.getElementById("search_parent_document-field").addEventListener("change", (ev) => {
							ev.preventDefault();
							openOptionsPossibleRefsParent();
						});

						// boton seleccionar
						document.querySelectorAll("[data-type='select-parent-linked-to-field']").forEach(elem => {
							elem.addEventListener("click", ev => {
								selectReferenceParent(elem.dataset.id);
							})
						});

						$("#sharedModal").modal("show");
					}).catch(error => {
						console.log(error)
					});

			}else {
				//limpiar si no esta referenciada
				cleanReferencedFields();
				alert("Selecciona una opción en NOMBRE");
			}

		}

		//seleccionar referencia para campo referencia en el form de cuentas
		let selectReferenceParent = (id) => {
			console.log("se selecciono "+id);
			$("#sharedModal").modal("hide");
			// $(".loader").show();
			let model_path = document.getElementById("account_type_account-field").dataset.modelPath;
			fetch("/"+model_path+"/"+id+".json")
				.then(resp => resp.json())
				.then(resp => {
					console.log(resp);
					document.getElementById("reference_id_account-field").value = resp.id;
					document.getElementById("reference_no_account-field").value = resp.invoice;
					document.getElementById("concept_reference_account-field").value = resp.concept_description;
					document.getElementById("amount_account-field").value = resp.amount;
				}).catch(err => {
					console.log(err);
				});
		}

		//-----------INICIALIZAR ELEMENTOS Y PONER CALLBACKS FORMULARIO DE CUENTAS------------
		// cambiar tipo de concepto
		concept_account_select_field.addEventListener("change", (ev) => {
			changeConceptAccount();
		});

		// cambiar sujeto (cliente/proveedor)
		document.getElementById("meta_id_account-field").addEventListener("change", (ev) => {
			changeClientProviderAccount();
		})

		document.getElementById('select-reference-parent-btn').addEventListener('click', (ev) => {
			openOptionsPossibleRefsParent();
		});

	}

	// si esta en el index
	let anticipations = document.querySelectorAll('[data-type="apply-anticipation-btn"]');
	if (anticipations.length > 0) {

		let model_path = document.getElementById("table-accounts").dataset.modelPath;

		let openApplyAnticipation = (id) => {
			// console.log(id);
			// $(".loader").show();
			fetch('/'+model_path+'/'+id+'/charges.json')
				.then(resp => resp.json())
				.then(resp => {
					// console.log(resp);

					let modal_content = "<div class='table-responsive'>"+
						"<table><thead><tr><th>Folio</th><th>Concepto</th><th>No. cargo</th><th>Fecha Apl.</th><th>Fecha Venc.</th><th>Doc.</th><th>Saldo</th><th></th></tr></thead><tbody>";
					resp.forEach(el => {
						modal_content += "<tr><td>"+el.invoice+"</td>"+
						"<td>"+el.concept_description+"</td><td>"+el.charge_number+"</td>"+
						"<td>"+el.application_date+"</td><td>"+el.expiration_date+"</td>"+
						"<td>"+el.no_document+"</td><td>"+el.total+"</td>"+
						"<td><a class='btn btn-success' data-id='"+el.id+"' data-type='select-parent-anticipation-btn'>+</a></td></tr>";
					});
					modal_content += "</tbody></table></div>";


					document.querySelector("#sharedModal .modal-title").innerHTML = "Aplicar Anticipo";
					document.querySelector("#sharedModal .modal-body").innerHTML = modal_content;
					document.querySelector("#sharedModal .modal-footer").innerHTML = '<button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>';
					
					// boton seleccionar
					document.querySelectorAll("[data-type='select-parent-anticipation-btn']").forEach(elem => {
						elem.addEventListener("click", ev => {
							let id_parent = elem.dataset.id;
							applyAnticipation(id, id_parent);
						})
					});

					$("#sharedModal").modal("show");

				}).catch(error => console.log(error));
		}

		let applyAnticipation = (anticipation_id, for_id) => {
			// console.log(anticipation_id);
			// console.log(for_id);
			// $(".loader").show();
			$("#sharedModal").modal("hide");

			fetch('/'+model_path+'/'+anticipation_id+'/apply_anticipation_to/'+for_id+'.json')
				.then(resp => resp.json())
				.then(resp => {
					// console.log(resp);
					alert(resp.notice);
					location.reload();
				}).catch(error => {
					console.log(error);
				});
		}

		// ----------------- CALLBACKS INDEX ACCOUNTS ---------------------
		anticipations.forEach(elem => {
			elem.addEventListener('click', ev => {
				ev.preventDefault();
				let id = ev.target.dataset.idAccount;
				openApplyAnticipation(id);
			});
		})
	}
});