document.addEventListener("turbolinks:load", () => {

  let menu_active = null;
  removeVisibleMenu = () => {
    if (menu_active) {
      menu_active.classList.remove("show");
    }
  }

  addListenerRows = () => {
    console.log("Trigger click")
    document.querySelectorAll("tr").forEach(box => {
      box.addEventListener("mouseup", logMouseButton)
    });
  }
  addListenerRows();

  function logMouseButton(e) {
    removeVisibleMenu();
    if (e.button === 2) {
      openMenu(e, this);
    }
  }

  function openMenu(e, element) {
    menu = element.getElementsByTagName("ul")[0]
    if (menu) {
      menu.classList.add("show")
      menu.style.position = "absolute";
      menu.style.left = e.pageX + "px";
      menu.style.top = e.pageY + "px";
      menu_active = menu;
    }
  }

  document.addEventListener('click', event => {
    removeVisibleMenu();
  });

});