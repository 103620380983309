document.addEventListener("turbolinks:load", () => {
	console.log('hola desde printer.js');

	let appName = document.querySelector('body').dataset.appName;
	let btnBatchExpensesReport = document.querySelector('[data-type="print_batch_expenses_report"]');
	if(btnBatchExpensesReport){
		let printExpensesBatchReport = async () => {
			// console.log('imprimir reporte')
			let route = btnBatchExpensesReport.dataset.route;
			let elements = await fetch(`${route}.json`).then(resp => resp.json());
			// console.log(elements);
			let content = "<div style='width=100%'>"+
				'<div style="float:left;width:100%; text-align:center;">'+
					`<h1 style="margin:0">${appName}</h1>`+
		      'Zacualtipán de Ángeles, Hidalgo, México, CP:43200 <br>'+
		    '</div>'+
		    '<div style="float:left;width:100%; text-align:center;">'+
				'<h2 style="margin:0">Gastos de corte '+elements.batch.number+' ('+elements.batch.quantity+' pz.)</h2>'+
		    	`<h3 style="margin:0">Costo por pieza $${(elements.total_simple / (elements.batch.quantity > 0 ? elements.batch.quantity : 1)).toFixed(4)}</h3>`+
		    '</div>'+
		    '<table style="text-align:center;width:100%">'+
		    	'<thead>'+
		    		'<tr><th>Fecha</th><th>Tipo de gasto</th><th>Descripción</th><th>Piezas</th><th>Cantidad</th><th>Observaciones</th><th>Total</th></tr>'+
		    	'</thead><tbody>';
		  elements.batch_expenses.forEach(el => {
		  	content += '<tr>'+
		  			`<td>${el.created_at}</td><td>${el.expense_type}</td><td>${el.description}</td><td>${el.expense_type == 'materia_prima' ? el.pieces : ''}</td><td>$${el.amount}</td><td>${el.observations}</td><td>$${el.total}</td>`+
		  		'</tr>';
		  });
		  content += `<tr><td colspan="6">TOTAL:</td><td>$${elements.total}</td></tr>`;
		  content += '</tbody></table>'
			openPrint(content);
		}

		btnBatchExpensesReport.addEventListener('click', ev => {
			printExpensesBatchReport();
		});
	}

	let btnFormatExpensesReport = document.querySelector('[data-type="print_format_expenses_report"]');
	if(btnFormatExpensesReport){
		let printExpensesFormatReport = async () => {
			// console.log('imprimir reporte')
			let route = btnFormatExpensesReport.dataset.route;
			let value = btnFormatExpensesReport.dataset.value;
			let elements = await fetch(`${route}.json?value=${value}`).then(resp => resp.json());
			// console.log(elements);
			let content = "<div style='width=100%'>"+
				'<div style="float:left;width:100%; text-align:center;">'+
					`<h1 style="margin:0">${appName}</h1>`+
		      'Zacualtipán de Ángeles, Hidalgo, México, CP:43200 <br>'+
		    '</div>'+
		    '<div style="float:left;width:100%; text-align:center;">'+
				'<h2 style="margin:0">Gastos de formato '+elements.format+' ('+elements.sum_batches+' pz.)</h2>'+
		    	`<h3 style="margin:0">Costo por pieza $${(elements.total_simple / (elements.sum_batches > 0 ? elements.sum_batches : 1)).toFixed(4)}</h3>`+
		    '</div>'+
		    '<table style="text-align:center;width:100%">'+
		    	'<thead>'+
		    		'<tr><th>Corte</th><th>Fecha</th><th>Tipo de gasto</th><th>Descripción</th><th>Piezas</th><th>Cantidad</th><th>Observaciones</th><th>Total</th></tr>'+
		    	'</thead><tbody>';
		  elements.batch_expenses.forEach(el => {
		  	content += '<tr>'+
		  			`<td>${el.batch}</td><td>${el.created_at}</td><td>${el.expense_type}</td><td>${el.description}</td><td>${el.expense_type == 'materia_prima' ? el.pieces : ''}</td><td>$${el.amount}</td><td>${el.observations}</td><td>$${el.total}</td>`+
		  		'</tr>';
		  });
		  content += `<tr><td colspan="7">TOTAL:</td><td>$${elements.total}</td></tr>`;
		  content += '</tbody></table>'
			openPrint(content);
		}

		btnFormatExpensesReport.addEventListener('click', ev =>{
			printExpensesFormatReport();
		});
	}

	let btnPrintAccountBalance = document.querySelector('[data-type="print_account_balance"]');
	if(btnPrintAccountBalance){
		let printAccountBalance = async () => {
			// console.log('imprimir estado de cuenta')
			let route = btnPrintAccountBalance.dataset.route;
			let elements = await fetch(`${route}.json`).then(resp => resp.json());
			// console.log(elements);
			let content = "<div style='width=100%'>"+
				'<div style="float:left;width:100%; text-align:center;">'+
					// `<h1 style="margin:0">${appName}</h1>`+
		      'Zacualtipán de Ángeles, Hidalgo, México, CP:43200 <br>'+
          `<h2 style="margin:0">Estado de cuenta de ${elements.meta_text_label}</h2>`+
		    '</div>'+
		    '<div style="float:left;width:100%; text-align:center; margin-bottom: 20px;">'+
          '<table style="text-align:center;width:100%;">'+
            `<tr><td width:"50%"><strong>${elements.meta_key_code_label}:</strong> ${elements.meta.key_code}</td><td width:"50%"><strong>${elements.meta_name_label}:</strong> ${elements.meta.name}</td></tr>`+
            `<tr><td width:"50%"><strong>${elements.meta_phone_label}:</strong> ${elements.meta.phone}</td><td width:"50%"><strong>${elements.meta_rfc_label}:</strong> ${elements.meta.rfc}</td></tr>`+
            `<tr><td width:"50%"><strong>${elements.meta_email_label}:</strong> ${elements.meta.email}</td><td width:"50%"><strong>${elements.meta_balance_label}:</strong> $${elements.meta_balance}</td></tr>`+
          '</table>'+
		    '</div>'+
		    '<table style="text-align:center;width:100%">'+
		    	'<thead>'+
		    		'<tr>'+
						  `<th>${elements.account_invoice_label}</th><th>${elements.account_seller_id_label}</th>`+
              `<th>${elements.account_concept_description_label}</th><th>${elements.account_no_document_label}</th>`+
              `<th>${elements.account_application_date_label}</th><th>${elements.account_expiration_date_label}</th>`+
              `<th>${elements.account_amount_label}</th><th>${elements.account_total_label}</th>`+
              `<th>${elements.account_observations_label}</th><th>${elements.account_charge_number_label}</th>`+
            '</tr>'+
		    	'</thead><tbody>';
		  elements.accounts.forEach(el => {
		  	content += '<tr>'+
		  			`<td>${el.invoice}</td><td>${el.seller_id}</td>`+
            `<td>${el.concept_description}</td><td>${el.no_document}</td>`+
            `<td>${el.application_date}</td><td>${el.expiration_date}</td>`+
            `<td>$${el.amount}</td><td>$${el.total}</td>`+
            `<td>${el.observations}</td><td>${el.charge_number}</td>`+
		  		'</tr>';
		  });
		  content += `<tr><td colspan="10"><strong>TOTAL ELEMENTOS:</strong> ${elements.accounts.length}</td></tr>`;
		  content += '</tbody></table>'
			openPrint(content);
		}

		btnPrintAccountBalance.addEventListener('click', ev => {
			printAccountBalance();
		});
	}

  // imprimir tabla productos
	let btnPrintTableProducts = document.querySelector('[data-type="print-products-btn"]');
	if(btnPrintTableProducts){
		let printTableProducts = async () => {
			// console.log('imprimir tabla productos')
      search_form = document.getElementById("all_search");
      $.get(search_form.action, $(search_form).serialize(), null, 'json').done((elements) => {
        // console.log(elements)
        let content = "<div style='width=100%'>"+
		      '<table style="text-align:center;width:100%">'+
            '<thead>'+
              '<tr>'+
                `<th>${elements.data_product_type_label}</th><th>${elements.composed_key_code_label}</th>`+
                `<th>${elements.description_label}</th><th>${elements.stocks_label}</th>`+
                `<th>${elements.input_unit_label}</th><th>${elements.output_unit_label}</th>`+
                `<th>${elements.price_one_label}</th><th>${elements.price_two_label}</th>`+
                `<th>${elements.price_three_label}</th><th>${elements.observations_label}</th>`+
                `<th>${elements.min_stock_label}</th><th>${elements.max_stock_label}</th>`+
                `<th>${elements.decimal_numbers_label}</th><th>${elements.cost_label}</th>`+
                `<th>${elements.average_cost_label}</th><th>${elements.stocking_time_label}</th>`+
                `<th>${elements.lots_label}</th><th>${elements.backorders_label}</th>`+
                `<th>${elements.product_line_id_label}</th>`+
              '</tr>'+
            '</thead><tbody>';
            elements.products.forEach(el => {
              content += '<tr>'+
                  `<td>${el.data_product_type}</td><td>${el.composed_key_code}</td>`+
                  `<td>${el.description}</td><td>${el.stocks}</td>`+
                  `<td>${el.input_unit}</td><td>${el.output_unit}</td>`+
                  `<td>$${el.price_one}</td><td>$${el.price_two}</td>`+
                  `<td>$${el.price_three}</td><td>${el.observations || ''}</td>`+

                  `<td>${el.min_stock}</td><td>${el.max_stock}</td>`+
                  `<td>${el.decimal_numbers}</td><td>$${el.cost}</td>`+
                  `<td>$${el.average_cost}</td><td>${el.stocking_time || ''}</td>`+
                  `<td>${el.lots}</td><td>${el.backorders}</td>`+
                  `<td>${el.line_key_code || ''}</td>`+
                '</tr>';
            });
          content += `<tr><td colspan="19"><strong>TOTAL ELEMENTOS:</strong> ${elements.products.length}</td></tr>`;
        content += '</tbody></table>';
        openPrint(content);
      }).fail(err => {
        console.log(err)
      });

			// let route = btnPrintTableProducts.dataset.route;
			// let elements = await fetch(`${route}.json`).then(resp => resp.json());
			// // console.log(elements);
			// let content = "<div style='width=100%'>"+
			// 	'<div style="float:left;width:100%; text-align:center;">'+
			// 		// `<h1 style="margin:0">${appName}</h1>`+
		  //     'Zacualtipán de Ángeles, Hidalgo, México, CP:43200 <br>'+
      //     `<h2 style="margin:0">Estado de cuenta de ${elements.meta_text_label}</h2>`+
		  //   '</div>'+
		  //   '<div style="float:left;width:100%; text-align:center; margin-bottom: 20px;">'+
      //     '<table style="text-align:center;width:100%;">'+
      //       `<tr><td width:"50%"><strong>${elements.meta_key_code_label}:</strong> ${elements.meta.key_code}</td><td width:"50%"><strong>${elements.meta_name_label}:</strong> ${elements.meta.name}</td></tr>`+
      //       `<tr><td width:"50%"><strong>${elements.meta_phone_label}:</strong> ${elements.meta.phone}</td><td width:"50%"><strong>${elements.meta_rfc_label}:</strong> ${elements.meta.rfc}</td></tr>`+
      //       `<tr><td width:"50%"><strong>${elements.meta_email_label}:</strong> ${elements.meta.email}</td><td width:"50%"><strong>${elements.meta_balance_label}:</strong> $${elements.meta_balance}</td></tr>`+
      //     '</table>'+
		  //   '</div>'+
		  //   '<table style="text-align:center;width:100%">'+
		  //   	'<thead>'+
		  //   		'<tr>'+
			// 			  `<th>${elements.account_invoice_label}</th><th>${elements.account_seller_id_label}</th>`+
      //         `<th>${elements.account_concept_description_label}</th><th>${elements.account_no_document_label}</th>`+
      //         `<th>${elements.account_application_date_label}</th><th>${elements.account_expiration_date_label}</th>`+
      //         `<th>${elements.account_amount_label}</th><th>${elements.account_total_label}</th>`+
      //         `<th>${elements.account_observations_label}</th><th>${elements.account_charge_number_label}</th>`+
      //       '</tr>'+
		  //   	'</thead><tbody>';
		  // elements.accounts.forEach(el => {
		  // 	content += '<tr>'+
		  // 			`<td>${el.invoice}</td><td>${el.seller_id}</td>`+
      //       `<td>${el.concept_description}</td><td>${el.no_document}</td>`+
      //       `<td>${el.application_date}</td><td>${el.expiration_date}</td>`+
      //       `<td>$${el.amount}</td><td>$${el.total}</td>`+
      //       `<td>${el.observations}</td><td>${el.charge_number}</td>`+
		  // 		'</tr>';
		  // });
		  // content += `<tr><td colspan="10"><strong>TOTAL ELEMENTOS:</strong> ${elements.accounts.length}</td></tr>`;
		  // content += '</tbody></table>'
			// openPrint(content);
		}

		btnPrintTableProducts.addEventListener('click', ev => {
			printTableProducts();
		});
	}
});