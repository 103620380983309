document.addEventListener("turbolinks:load", function() {
	console.log("hola desde product_lines.js");
	//ckeck de form >si usa subgrupos
	let subgroups_input = document.getElementById("subgroups-input");
	//si esta en el form
	if (subgroups_input){
		//revisar si esta checked y poner/quitar otros campos
		let checkSubgroupsInput = () => {
			// console.log('ccheckSubgroupsInput')
			var subgroups_check = subgroups_input.checked;
			//console.log(subgroups_check)
			let subgroups_options = document.querySelectorAll("[data-type='subgroups-options']");
			//console.log(subgroups_options)
			if (subgroups_check) 
				for(let elem of subgroups_options)
					elem.classList.remove("d-none");
			else
				for(let elem of subgroups_options)
					elem.classList.add("d-none");
		}

		//llenar el campo ejemplo clave en lineas
		let fillCodeKeyExampleField = () => {
			let group_length = parseInt(document.getElementById("group_length-field").value);
			let separator_one = document.getElementById("separator_one-field").value;
			let length_sub_one = parseInt(document.getElementById("length_sub_one-field").value);
			let separator_two = document.getElementById("separator_two-field").value;
			let length_sub_two = parseInt(document.getElementById("length_sub_two-field").value);
			let arr = [group_length, length_sub_one, length_sub_two];
			let arr1 = [];

			//console.log(arr);
			arr.forEach(function (el) {
				let result = '';
				$.each(new Array(el||0),function(letter) {
			  		result += "X";
				});
				arr1.push(result);
			})
			//console.log(arr1);

			let code_key = arr1[0] + separator_one + arr1[1] + separator_two + arr1[2];
			document.getElementById("code-key-example-field").value = code_key;
			limitMaxlenghtSubgroups();
			//console.log(code_key);
		}

		//limitar el maximo de caracteres para los nested subgrupos en lineas
		let limitMaxlenghtSubgroups = () => {
			let length_sub_one = parseInt(document.getElementById("length_sub_one-field").value);
			let length_sub_two = parseInt(document.getElementById("length_sub_two-field").value);

			// console.log(length_sub_one)
			// console.log(length_sub_two)

			document.querySelectorAll("[data-type='values-subgroup_one']").forEach(function(el) {
				let new_value = el.value.substr(0,length_sub_one);
				el.value = new_value;
				el.setAttribute("maxlength",length_sub_one);
			});

			document.querySelectorAll("[data-type='values-subgroup_two']").forEach(function(el) {
				let new_value = el.value.substr(0,length_sub_two);
				el.value = new_value;
				el.setAttribute("maxlength",length_sub_two);
			});
		}


		//-----------INICIALIZAR ELEMENTOS Y PONER CALLBACKS FORMULARIO DE LINEAS DE PRODUCTOS------------

		document.querySelectorAll("[data-type='calculate-code-key-example']").forEach((field)=>{
			field.addEventListener("change", (el) => {
				fillCodeKeyExampleField();
			})
		});
		fillCodeKeyExampleField();

		subgroups_input.addEventListener("change", event => {
			checkSubgroupsInput();
		});
		checkSubgroupsInput();

		$('#subgroup_one').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
			// console.log('uno')
			limitMaxlenghtSubgroups();
		});
		$('#subgroup_two').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
			// console.log('dos')
			limitMaxlenghtSubgroups();
		});
	}
});